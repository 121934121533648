// Generated by Framer (6d96884)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["bq6HknN3R", "SHaao7P84"];

const variantClassNames = {bq6HknN3R: "framer-v-17ktq1m", SHaao7P84: "framer-v-7zfj31"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {Active: "bq6HknN3R", Inactive: "SHaao7P84"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "bq6HknN3R", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "bq6HknN3R", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-68D4C", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-17ktq1m", className)} data-framer-name={"Active"} layoutDependency={layoutDependency} layoutId={"bq6HknN3R"} ref={ref} style={{backgroundColor: "rgb(22, 22, 24)", borderBottomLeftRadius: 16, borderBottomRightRadius: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16, opacity: 1, ...style}} transition={transition} variants={{SHaao7P84: {backgroundColor: "var(--token-eada6075-df84-4c9f-9fba-107a6537bba4, rgba(18, 18, 23, 0.75))", opacity: 0.25}}} {...addPropertyOverrides({SHaao7P84: {"data-framer-name": "Inactive"}}, baseVariant, gestureVariant)}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-68D4C [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-68D4C .framer-u9u0w8 { display: block; }", ".framer-68D4C .framer-17ktq1m { height: 4px; overflow: visible; position: relative; width: 4px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 4
 * @framerIntrinsicWidth 4
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"SHaao7P84":{"layout":["fixed","fixed"]}}}
 */
const FramerW4z9cbC0Y: React.ComponentType<Props> = withCSS(Component, css, "framer-68D4C") as typeof Component;
export default FramerW4z9cbC0Y;

FramerW4z9cbC0Y.displayName = "Bullet Selector";

FramerW4z9cbC0Y.defaultProps = {height: 4, width: 4};

addPropertyControls(FramerW4z9cbC0Y, {variant: {options: ["bq6HknN3R", "SHaao7P84"], optionTitles: ["Active", "Inactive"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerW4z9cbC0Y, [])